<template>
  <div class="stories template-1">
    <header class="page-header container mx-sm">
      <h1>{{ translations.tcSuccessStory }}</h1>
      <BannerAid :bannerLink="`success-story|` + userSelectedArticleKey" />
    </header>
    <div class="page-body container mx-sm">
      <div v-if="alpha.title !== ''" class="story">
        <h2 class="title-1">{{ alpha.title }}</h2>
        <h4 class="title-2">{{ returnFormattedDate(alpha.testimony_date) }}</h4>
        <p v-html="adjustMessage(alpha.article)"></p>
        <div class="inline-flex">
          <button class="btn btn-primary mr-4" @click="handleSeeAllClick">{{ translations.tcSeeAll }}</button>
        </div>
      </div>
    </div>
    <div class="page-footer"></div>
    <div class="page-extra">
      <div class="container bg-primary more-testimonials">
        <h2 class="text-white">{{ translations.tcMoreSuccessStories }}</h2>
        <div class="d-flex">
          <div class="bg-white" v-for="(item, indx) in [beta, gamma, delta]" :key="indx">
            <h3>{{ item.title }}</h3>
            <div class="d-flex meta-date">
              <div>{{ returnFormattedDate(item.testimony_date) }}</div>
            </div>
            <p>{{ charLimit(item.description, 120) }}</p>
            <a href="#" @click.prevent="handleReadMoreClick(item.art_key)" class="btn btn-secondary">
              {{ translations.tcReadMore }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BannerAid from '@/components/BannerAid.vue'
import { messagePresentationMixin } from '@/mixins/messagePresentationMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'success-story',
  mixins: [messagePresentationMixin, translationMixin],
  data() {
    return {
      addItems: { display: false },
      empty_story: {
        title: '',
        article: '',
        country: '',
        testimony_date: '',
      },
      hiddenItems: { display: false },
      summaryToggle: false,
      translations: {},
    }
  },
  methods: {
    ...mapActions({
      getSpecificStory: 'successStory/getSpecificStory',
      getSuccessStories: 'successStory/getSuccessStories',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedArticleKey: 'user/setSelectedArticleKey',
    }),
    charLimit(text, limit) {
      return !text ? '' : text.length > limit ? text.substring(0, limit) + '...' : text
    },
    async handleSeeAllClick() {
      await this.setSelectedArticleKey(null)
      this.$router.push('/get-connected/stay-informed/success-stories')
    },
    async handleReadMoreClick(evt) {
      await this.setSelectedArticleKey(evt)
      await this.handleSpecificStoryRequest()
    },
    async handleSpecificStoryRequest() {
      try {
        await Promise.all([this.setLoadingStatus(true), this.getSpecificStory(this.userSelectedArticleKey)]).then(
          () => {
            this.summaryToggle = false
          }
        )
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
      storySummary: 'successStory/storySummary',
      successStories: 'successStory/successStories',
      userSelectedArticleKey: 'user/userSelectedArticleKey',
    }),
    alpha() {
      return (
        this.successStories.filter((story) => {
          return story.art_key === this.userSelectedArticleKey
        })[0] || this.empty_story
      )
    },
    beta() {
      return this.successStories[1] || this.empty_story
    },
    gamma() {
      return this.successStories[2] || this.empty_story
    },
    delta() {
      return this.successStories[3] || this.empty_story
    },
  },
  async created() {
    await this.getViewTranslations()
  },
  async mounted() {
    try {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.getSuccessStories({
          rows: 4,
          selected_key: this.userSelectedArticleKey,
        }),
      ])
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    BannerAid: BannerAid,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.stories {
  .title-1 {
    margin-bottom: 0;
    color: #000;
    font-family: $DIN;
    font-size: 40px;
    letter-spacing: 0.03em;
    line-height: 44px;
    text-transform: uppercase;
  }

  .title-2 {
    margin-bottom: 8px;
    color: $teal-700;
    font-size: 30px;
    letter-spacing: 0.03em;
    line-height: 44px;
    text-transform: none;
  }

  p {
    margin-bottom: 36px;
    font-size: 18px;
    font-family: $lora;
    letter-spacing: 0.03em;
    line-height: 36px;
  }
}

.page-extra {
  @include breakpoint(sm) {
    padding: 0 15px;
  }

  .more-testimonials {
    padding: 50px 60px 60px;

    @include breakpoint(sm) {
      padding: 30px 68px 68px;
    }

    >.d-flex {
      @include breakpoint(sm) {
        flex-wrap: wrap;
      }

      >div {
        margin-right: 35px;
        padding: 21px 33px 30px;

        @include breakpoint(sm) {
          width: 100%;
          margin-bottom: 24px;
          margin-right: 0;
          padding: 34px 50px;
        }

        &:last-of-type {
          margin-right: 0;

          @include breakpoint(sm) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  h2 {
    margin-bottom: 30px;
    font-family: $DIN;
    font-size: 40px;
    letter-spacing: 1.33px;
    line-height: 44px;
  }

  h3 {
    margin-bottom: 2px;
    color: #000;
    font-family: $DIN;
    font-size: 26px;
    letter-spacing: 0.78px;
    line-height: 44px;
  }

  .meta-date {
    margin-bottom: 11px;
    font-size: 12px;
    letter-spacing: 0.6px;
    line-height: 36px;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 43px;
    font-family: $lora;
    font-size: 16px;
    letter-spacing: 0.64px;
    line-height: 24px;
  }
}
</style>
